import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
// Sentry.init({
//   dsn: "https://6c88068c5af64aaaad7df3d45bf434a2@o224096.ingest.sentry.io/6191433",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


const addTokenToWallet = (tokenSymbol: string, tokenAddress: string) => async () => {
  const tokenImage = "/assets/icon/icon.png";

  if (window.ethereum) {
      try {
          await window.ethereum.request({
              method: "wallet_watchAsset",
              params: {
                  type: "ERC20",
                  options: {
                      address: tokenAddress,
                      symbol: tokenSymbol,
                      decimals: 18,
                      image: tokenImage,
                  },
              },
          });
      } catch (error) {
          console.log(error);
      }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
