import {
  IonApp, IonRouterOutlet, setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Redirect, Route } from 'react-router-dom';
import { UseWalletProvider } from "use-wallet";
import usePromptNetwork from './hooks/usePromptNetwork';
import Inventory from './pages/Inventory';
import Detail from './pages/Detail';
/* Theme variables */
import './theme/variables.css';
import Mint from './pages/Mint';
import { config } from './config';
import Item from './pages/Item';
import Staking from './pages/Staking';
import StakeItem from './pages/StakeItem';



setupIonicReact({ mode: "md" });

const App: React.FC = () => {
  usePromptNetwork()
  return (
    <IonApp>
      <IonReactRouter>
        <UseWalletProvider autoConnect >
          <IonRouterOutlet animated={false}>
            <Route path="/FTM">
              <Mint />
            </Route>
            <Route path="/FTM/:id">
              <Detail />
            </Route>
            <Route path="/item/:id">
              <Item />
            </Route>
            <Route exact path="/palace">
              <Staking />
            </Route>
            <Route path="/palace/:id">
              <StakeItem />
            </Route>
            <Route path="/collection/">
              <Staking />
            </Route>
            <Route exact path="/">
              <Mint />
            </Route>
          </IonRouterOutlet>
        </UseWalletProvider>
      </IonReactRouter>
    </IonApp >
  )
};

export default App;
