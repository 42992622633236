import { IonAvatar, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { BigNumber, ContractTransaction, ethers } from 'ethers';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';
import LoginButton from '../components/LoginButton';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { MagicalItem__factory } from '../contracts/factories/MagicalItem__factory';
import { MagicalItem } from '../contracts/MagicalItem';
import { ActionButton, CollectionInventory, ERC1155CollectionInventory, reloadPage, StakedCollectionInventory, UnstakeButton, UnStakedCollectionInventory } from './Inventory';
import { contractAddress } from './Mint';
import { PotionPalace__factory } from '../contracts/factories/PotionPalace__factory';
import { PotionPalace } from '../contracts/PotionPalace';
import { config } from '../config';
import { ERC20 } from '../contracts/ERC20';
import { ERC20__factory } from '../contracts/factories/ERC20__factory';


export const Staking: React.FC = () => {
    const { isConnected, ethereum, account } = useWallet();
    const [potion, setPotion] = useState<BigNumber | undefined>()
    const [totalSupply, setTotalSupply] = useState<BigNumber | undefined>()
    const [stakeTransaction, setStakeTransaction] = useState<ContractTransaction | undefined>()

    const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
    useEffect(() => {

        const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
        nftContract && nftContract.on("Transfer", (from, to, amount, event) => {
            if (String(to).toUpperCase() === String(account).toUpperCase() && String(from).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
            if (String(from).toUpperCase() === String(account).toUpperCase() && String(to).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
        })

        nftContract && nftContract.on("Transfer", (from, to, amount, event) => {
            if (String(to).toUpperCase() === String(account).toUpperCase() && String(from).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
            if (String(from).toUpperCase() === String(account).toUpperCase() && String(to).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
        })
        nftContract && nftContract.totalSupply().then((totalSupply) => {
            setTotalSupply(totalSupply);
        });
    })
    useEffect(() => {

        const potionContract: ERC20 | undefined = signer && ERC20__factory.connect("0x3eda36088b931098e8e472748840b3df78268c72", signer);
        potionContract && account && potionContract.balanceOf(account).then((amount) => {
            setPotion(amount)
        });
    }, [isConnected, account])
    return (
        <IonPage>
            <IonHeader>
                <IonItem>
                    <IonButtons slot="start">

                        <IonButton routerLink='/' routerDirection='root' color="dark" fill='clear'>
                            <IonIcon icon={arrowBack} />
                        </IonButton>
                    </IonButtons>


                    <IonTitle color="tertiary">Potion Palace (Staking)</IonTitle>
                    <IonButtons slot="end">
                        {potion && <IonChip color="tertiary">
                            <IonText color="tertiary">
                                {potion && ethers.utils.formatEther(potion)}
                            </IonText>
                            <IonAvatar>
                                <IonImg src="https://magepunks.cc/assets/images/potion-icon.png" />
                            </IonAvatar>
                        </IonChip>}
                        <LoginButton />
                    </IonButtons>
                </IonItem>
            </IonHeader >
            <IonContent>
                <IonGrid>
                    {stakeTransaction && <a target="_blank" href={config.explorerUrl + "tx/" + stakeTransaction?.hash}>
                        <IonChip color="tertiary"> View transaction {stakeTransaction?.hash.slice(0, 7)} on block explorer</IonChip>
                    </a>
                    }
                    {totalSupply && isConnected() ? <IonRow>
                        <UnStakedCollectionInventory actions={ActionButton} size='small' name='Arcturian' contract_address={contractAddress} currentSupply={totalSupply.toNumber()} />
                        <StakedCollectionInventory actions={UnstakeButton} size='small' name='Arcturian' contract_address={config.stakingContractAddress} currentSupply={totalSupply.toNumber()} />
                    </IonRow> : <LoginButton />}
                    <div style={{ position: "absolute", width: "100%", left: "0px", right: "0px" }}>
                        <img src="https://arcturians.cc/assets/images/palace.png" />
                    </div>
                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default Staking;
