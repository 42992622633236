import { IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonLoading, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { BigNumber, ContractTransaction, ethers, utils } from 'ethers';
import { documentLockOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import LoginButton from '../components/LoginButton';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { ArcturianCard } from './Inventory';
import Countdown from 'react-countdown';
import { unknownAttributes } from '../data/unknownAttributes';
import { config } from '../config';
import { ERC20 } from '../contracts/ERC20';
import { ERC20__factory } from '../contracts/factories/ERC20__factory';
import { connected } from 'process';
import { useWallet } from 'use-wallet';

export interface NFTBalance {
  token_address: string;
  token_id: string;
  contract_type: string;
  owner_of: string;
  block_number: string;
  block_number_minted: string;
  token_uri?: string | undefined;
  metadata?: string | undefined;
  synced_at?: string | undefined;
  amount?: string | undefined;
  name: string;
  symbol: string;
}
interface NFTMetadata {
  token_address: string;
  name: string;
  abi?: string | undefined;
  supports_token_uri?: number | undefined;
  synced_at?: string | undefined;
  symbol: string;
  contract_type: string
}



export const contractAddress = config.nftContractAddress;




const Mint: React.FC = () => {

  const { connect, ethereum, account, chainId } = useWallet()
  const [magePunkId, setArcturianId] = useState("")
  const [magePunkAttributes, setArcturianAttributes] = useState<undefined | any>()
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();

  const [present] = useIonAlert();
  const [status, setStatus] = useState<"idle" | "minting" | "minted" | "sold-out">("idle")
  const [supply, setSupply] = useState<number>(0)
  const [mintTransaction, setMintTransaction] = useState<ContractTransaction | undefined>()
  const [potion, setPotion] = useState<BigNumber | undefined>()
  const [maxSupply, setMaxSupply] = useState<number>(0)
  const [mintPrice, setMintPrice] = useState<BigNumber | undefined>()
  const [baseUri, setBaseUri] = useState("/assets/metadata/")
  const [whitelisted, setWhitelisted] = useState(false)

  useEffect(() => {

    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
    nftContract && account && nftContract.addressMintPrice(account).then((cost) => {
      console.log("set mint price", cost)
      setMintPrice(cost);
    });
    nftContract && account && nftContract.whitelisted(account).then((whitelist) => {
      setWhitelisted(whitelist);
    });
    if (chainId !== 250) {
      return;
    }
    const potionContract: ERC20 | undefined = signer && ERC20__factory.connect("0x3eda36088b931098e8e472748840b3df78268c72", signer);
    potionContract && account && potionContract.balanceOf(account).then((amount) => {
      setPotion(amount)
    });
  }, [account, chainId])
  useEffect(() => {

    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
    nftContract && nftContract.totalSupply().then((amount) => {
      console.log("supply", amount.toNumber())
      setSupply(amount.toNumber())
    });
    if (chainId !== 250) {
      return;
    }
  }, [account, chainId, status])



  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);

    account && nftContract && nftContract.on("Transfer", (from, to, amount, event) => {
      if (to === account && from === "0x0000000000000000000000000000000000000000") {
        setStatus("minted");
        const mintedId = event.args[2].toString()
        setArcturianId(event.args[2].toString());
        fetch("/assets/metadata/" + mintedId + ".json").then(async (res) => {
          const json = await res.json()
          setArcturianAttributes(json);
        })
        setArcturianId(event.args[2].toString());
      }
    })
  }, [account, chainId, connected, status])


  return (
    <IonPage>
      <IonHeader>
        <IonItem>
          <IonButtons slot="start">
            <IonRouterLink href={'https://docs.magepunks.cc/arturus-gate'}>
              <IonTitle size='large' color="primary">
                <IonText color="medium">
                  Arturus Gate ⛩️
                </IonText>
                <IonChip color='primary'>
                  docs
                </IonChip>
              </IonTitle>
            </IonRouterLink>

          </IonButtons>
          <IonButton fill="clear" routerLink='/collection'>
            Space Potion Palace (Staking)
          </IonButton>
          <IonButtons slot="end">
            <LoginButton />
          </IonButtons>
        </IonItem>
      </IonHeader>
      <IonContent>
        <IonGrid>

          <IonRow>
            <IonCol sizeMd='0' sizeLg="2">

            </IonCol>
            <IonCol sizeXs='12' sizeMd='12' sizeLg='4'>
              {status !== "minted" && <IonCard title={"Magepunk"} color="light">
                <IonCardHeader color="paper">
                  <IonTitle size='large'>
                  </IonTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonImg src="https://smol.mypinata.cloud/ipfs/QmNRp6jnAEXCjp2294UqaLZJ3arJhbjmfePTqHQJijj3rp/8.png" />
                  <IonText color="medium">
                    <IonItemDivider >
                      Arcturians, extradimensional beings from the star Arcturus.
                    </IonItemDivider>
                  </IonText>
                  <IonItemDivider color="clear" />
                  {/* {(unknownAttributes).map((x: any, i) => <IonChip key={i} color="medium">
                    {x && x.trait_type && < IonLabel color="tertiary">
                      {String(x.trait_type)}:
                    </IonLabel>}
                    {x && x.value && String(x.value)}
                  </IonChip>)} */}
                </IonCardContent>
              </IonCard >}
              {magePunkAttributes && <ArcturianCard id={parseInt(magePunkId)} metadata={magePunkAttributes} />}
              {chainId !== 250 && account && <IonLoading message={"Please Switch to FTM..." + chainId} isOpen={chainId !== config.chainId!} />}


            </IonCol>
            <IonCol sizeXs='12' sizeMd='12' sizeLg='4'>

              <IonCard title={"Arcturians"} color="light">
                <IonCardHeader color="">
                  <IonCardTitle>
                    <IonRow>
                      <IonCol>
                        <IonTitle size='large' color="tertiary">
                          Arcturianᵍᵐ
                        </IonTitle>
                      </IonCol>
                      <IonCol>
                        {supply} / 108 👾
                      </IonCol>
                      {/* <IonCol>
                        {mintPrice && <>Mint fee:<IonText color="secondary">{utils.formatEther(mintPrice) + " FTM"}</IonText></>}
                      </IonCol> */}
                    </IonRow>

                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItemDivider color="clear" />
                  <IonButton size="large" expand='full' color='tertiary' onClick={() => {
                    window.open("https://paintswap.finance/marketplace/collections/0x4af7ad773e67eCF00299F7585caCc8ddbB62DC5C");
                  }}>
                    Available on Paintswap
                  </IonButton>
                  <IonItemDivider color="clear" />

                  <IonButton size="large" fill='outline' expand='full' color='primary' routerLink='/palace'>
                    ⛩️ Enter Palace ⛩️
                  </IonButton>

                  {/* {<IonImg src={status === "minting" ? "/assets/images/gate-open.png" : status === "minted" ? "/assets/images/gate-passed.png" : "/assets/images/gate-closed.png"} />} */}
                  {/* {signer && mintPrice && <IonButton size='large' fill='solid' color="tertiary" expand='full' onClick={() => {
                    const nftContract = Arcturians__factory.connect(contractAddress, signer)
                    nftContract.PortalArcturian({ value: mintPrice }).then((tx) => {
                      setArcturianAttributes(undefined);
                      setMintTransaction(tx);
                      setStatus("minting")
                    }).catch((e) => {
                      present({
                        header: e.data.message,
                        message: e.message,
                        buttons: [
                          { text: 'Ok' },
                        ],
                      })
                    });
                  }}>
                    Mint
                  </IonButton>} */}
                  {/* <IonButton href='https://www.ghostgarden.io/' fill='clear'>
                    Mint a ghostly (50FTM)
                  </IonButton> */}

                  {status !== "minted" && !signer && <IonButton size='large' fill='solid' expand='full' onClick={() => {
                    connect("injected");
                  }}>
                    Connect to mint
                  </IonButton>}
                  {status === "minting" && <>
                    <a target="_blank" href={config.explorerUrl + "tx/" + mintTransaction?.hash}>
                      <IonChip color="tertiary"> View transaction {mintTransaction?.hash.slice(0, 7)} on block explorer</IonChip>
                    </a>
                  </>}

                  {status === "minted" && <IonButton size='large' href={"https://twitter.com/intent/tweet?text=Just minted arcturian " + magePunkId + " at https://arcturians.cc on $FTM"} expand='full'>
                    Share on Twitter
                  </IonButton>}
                  <IonItemDivider color="clear" />

                  <IonItemDivider color="clear" />
                  <IonItem href={config.explorerUrl + "address/" + contractAddress} >
                    <IonIcon icon={documentLockOutline} />
                    <IonText color="medium">View Contract: {contractAddress}
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard >
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <div style={{ position: 'absolute', right: 10, bottom: 10 }}>

        <IonChip onClick={() => {
          window.open("https://twitter.com/lil_esper");
        }}>
          <IonAvatar >
            <IonImg src='https://magepunks.cc/assets/images/1.png' />
          </IonAvatar>
          <IonLabel>

          </IonLabel>
          made by lil esper</IonChip>


      </div>
    </IonPage >
  );
};

export default Mint;
