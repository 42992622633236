import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import LoginButton from '../components/LoginButton';
import { arrowBack, arrowForward, balloon } from 'ionicons/icons';
import { ArcturianCard, NFTCard } from './Inventory';
import { useParams } from 'react-router';
import { BigNumber, ethers } from 'ethers';
import { useWallet } from 'use-wallet';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { contractAddress } from './Mint';

export interface NFTBalance {
  token_address: string;
  token_id: string;
  contract_type: string;
  owner_of: string;
  block_number: string;
  block_number_minted: string;
  token_uri?: string | undefined;
  metadata?: string | undefined;
  synced_at?: string | undefined;
  amount?: string | undefined;
  name: string;
  symbol: string;
}
interface NFTMetadata {
  token_address: string;
  name: string;
  abi?: string | undefined;
  supports_token_uri?: number | undefined;
  synced_at?: string | undefined;
  symbol: string;
  contract_type: string
}

const Detail: React.FC = () => {

  const { id } = useParams<{ id: string }>()
  const { connect, ethereum, account } = useWallet()
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  const [status, setStatus] = useState<"loading" | "idle" | "hidden">("loading");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [magePunkAttributes, setArcturianAttributes] = useState<undefined | any>()
  useEffect(() => {

    // const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
    // nftContract && nftContract.totalSupply().then((amount) => {
    //   if (amount.toNumber() > parseInt(id)) {
    //     setStatus("idle");
    //   } else {
    //     setStatus("hidden");
    //   }
    // });
    // nftContract && nftContract.tokenURI(id).then((tokenUri) => {
    //   if (tokenUri) {
    //     setStatus("idle");
    //   } else {
    //     setStatus("hidden");
    //   }
    // });
    // nftContract && id && nftContract.ownerOf(BigNumber.from(id)).then((address) => {
    //   setOwnerAddress(address);
    // });
    id && fetch("/assets/metadata/" + (id) + ".json").then(async (res) => {
      const json = await res.json();
      setArcturianAttributes(json);
    })

  }, [account, id])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <a href="/">
            <IonTitle>Arcturians</IonTitle>
          </a>
          <IonButtons slot="end">
            <LoginButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent >

        <IonGrid>
          {<IonItem >
            <IonButtons slot="start">
              <IonButton routerLink={"/FTM/" + (parseInt(id) - 1)}>
                <IonText color="medium">
                  <IonIcon icon={arrowBack} />
                </IonText>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton routerLink={"/FTM/" + (parseInt(id) + 1)}>
                <IonText color="medium">
                  <IonIcon icon={arrowForward} />
                </IonText>
              </IonButton>
            </IonButtons>
          </IonItem>}

          <IonRow>
            <IonCol />
            {magePunkAttributes && <ArcturianCard id={parseInt(id)} metadata={magePunkAttributes} />}
            {ownerAddress && <IonItem href={'https://ftmscan.com/address/' + ownerAddress}>
              <IonText color="medium">
                Owner {ownerAddress}
              </IonText>
            </IonItem>}
            <IonCol />
          </IonRow>

        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Detail;
