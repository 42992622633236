import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonLoading, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import LoginButton from '../components/LoginButton';
import { arrowBackOutline, balloon } from 'ionicons/icons';
import { ArcturianCard, NFTCard, reloadPage } from './Inventory';
import { useParams } from 'react-router';
import { BigNumber, ethers } from 'ethers';
import { useWallet } from 'use-wallet';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { contractAddress } from './Mint';
import { config } from '../config';
import { PotionPalace__factory } from '../contracts/factories/PotionPalace__factory';
import { PotionPalace } from '../contracts/PotionPalace';


const StakedItem: React.FC = () => {

    const { id } = useParams<{ id: string }>()
    const { connect, ethereum, account } = useWallet()
    const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
    const [status, setStatus] = useState<"loading" | "idle" | "hidden" | "transacting">("loading");
    const [tokenUri, setTokenUri] = useState<string | undefined>();
    const [ownerAddress, setOwnerAddress] = useState("");
    const [magePunkAttributes, setArcturianAttributes] = useState<undefined | any>()

    useEffect(() => {

        const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
        nftContract && nftContract.on("Transfer", (from, to, amount, event) => {
            if (String(to).toUpperCase() === String(account).toUpperCase() && String(from).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
            if (String(from).toUpperCase() === String(account).toUpperCase() && String(to).toUpperCase() === String(config.stakingContractAddress).toUpperCase()) {
                reloadPage();
            }
        })


        setTokenUri("/assets/metadata/" + id + ".json");
        nftContract && id && nftContract.ownerOf(BigNumber.from(id)).then((address) => {
            setOwnerAddress(address);
        });
        (async () => {

            id && tokenUri && fetch(tokenUri).then(async (res) => {
                const json = await res.json();
                setArcturianAttributes(json);
            })

        })()

    }, [account, id, tokenUri])
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButton color="clear" routerLink='/'>
                        <IonIcon icon={arrowBackOutline} />
                    </IonButton>

                    <IonButton color="clear" routerLink='/' fill='clear'>
                        Potion Palace Gates
                    </IonButton>
                    <IonButtons slot="end">
                        <LoginButton />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <IonGrid>

                    <IonRow>
                        <IonCol />
                        {magePunkAttributes && <ArcturianCard id={parseInt(id)} metadata={magePunkAttributes} />}
                        <IonCol>

                            {ownerAddress === account && <> <IonButton onClick={() => {
                                const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
                                nftContract && nftContract.setApprovalForAll(config.stakingContractAddress, true).then(() => {
                                });

                            }}>
                                Approve Palace
                            </IonButton>
                                <IonButton onClick={() => {
                                    const potionPalace: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
                                    potionPalace && potionPalace.enterPalace([parseInt(id)]).then(() => {
                                        setStatus("transacting");
                                    });
                                }}>
                                    Enter Palace
                                </IonButton></>}
                            {ownerAddress === config.stakingContractAddress && <IonButton expand='full' onClick={() => {
                                const potionPalace: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
                                potionPalace && potionPalace.exitPalace([parseInt(id)]).then(() => {
                                    setStatus("transacting");

                                });
                            }}>
                                Exit Palace
                            </IonButton>}
                            {ownerAddress === config.stakingContractAddress && <IonButton expand='full' onClick={() => {
                                const potionPalace: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
                                potionPalace && potionPalace.exitPalace([parseInt(id)]).then(() => {

                                });
                            }}>
                                Collect Potion
                            </IonButton>}
                            {status === "transacting" && <IonLoading message={"Traversing the Palace Gates"} spinner={"crescent"} isOpen={true} />}
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default StakedItem;
