
export const config: { [env: string]: any } = {
    chainId: 250,
    networkName: 'Fantom Opera Main net',
    explorerUrl: 'https://ftmscan.com/',
    defaultProvider: "https://rpcapi.fantom.network",
    chain: "FTM",
    network: "mainnet",
    networkId: 250,
    name: "arcturian",
    nativeCurrency: { "name": "Fantom", "symbol": "FTM", "decimals": 18 },
    nftContractAddress: "0x4af7ad773e67eCF00299F7585caCc8ddbB62DC5C",
    stakingContractAddress: "0x746b38f54D68342CD0ce7063B12B1e1a1AeFe85e"
};
export const testConfig: { [env: string]: any } = {
    chainId: 43113,
    networkName: 'Avax Fuji testnet',
    explorerUrl: 'https://testnet.snowtrace.io/',
    defaultProvider: "https://api.avax-test.network/ext/bc/C/rpc",
    chain: "FAVAX",
    name: "arcturian",
    network: "testnet",
    networkId: 43113,
    nativeCurrency: { "name": "Fuji Avax", "symbol": "FAVAX", "decimals": 18 },
    nftContractAddress: "0xf40490BFb152D16538Dfa9a074fF00e41519b4dc",
    stakingContractAddress: "0x71909A03f9076c6330EeD80d34196E6498d2C921"
};

