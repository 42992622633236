import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ExploreContainer from '../components/ExploreContainer';
import LoginButton from '../components/LoginButton';
import { balloon } from 'ionicons/icons';
import { ArcturianCard, NFTCard } from './Inventory';
import { useParams } from 'react-router';
import { BigNumber, ethers } from 'ethers';
import { useWallet } from 'use-wallet';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { contractAddress } from './Mint';


const Item: React.FC = () => {

    const { id } = useParams<{ id: string }>()
    const { connect, ethereum, account } = useWallet()
    const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
    const [status, setStatus] = useState<"loading" | "idle" | "hidden">("loading");
    const [ownerAddress, setOwnerAddress] = useState("");
    const [magePunkAttributes, setArcturianAttributes] = useState<undefined | any>()
    useEffect(() => {

        const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
        nftContract && nftContract.totalSupply().then((amount) => {
            if (amount.toNumber() > parseInt(id)) {
                setStatus("idle");
            } else {
                setStatus("hidden");
            }
        });
        nftContract && nftContract.tokenURI(id).then((tokenUri) => {
            if (tokenUri) {
                setStatus("idle");
            } else {
                setStatus("hidden");
            }
        });
        nftContract && id && nftContract.ownerOf(BigNumber.from(id)).then((address) => {
            setOwnerAddress(address);
        });
        (async () => {

            id && fetch("/assets/metadata/" + (id) + ".json").then(async (res) => {
                const json = await res.json();
                setArcturianAttributes(json);
            })

        })()

    }, [account, id])
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <a href="/">
                        <IonTitle>Arcturians</IonTitle>
                    </a>
                    <IonButtons slot="end">
                        <LoginButton />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <IonGrid>

                    <IonRow>
                        <IonCol />
                        {magePunkAttributes && <ArcturianCard id={parseInt(id)} metadata={magePunkAttributes} />}
                        {ownerAddress && <IonItem href={'https://ftmscan.com/address/' + ownerAddress}>
                            <IonText color="medium">
                                Owner {ownerAddress}
                            </IonText>
                        </IonItem>}
                        <IonCol />
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage >
    );
};

export default Item;
