import { IonButton, IonChip, IonImg, IonText } from '@ionic/react';
import { formatEther } from 'ethers/lib/utils';
import { useWallet } from "use-wallet";
import './ExploreContainer.css';
const LoginButton: React.FC = () => {

    const { connect, account, balance, isConnected, error, chainId, connector, status, connectors, reset, type, ethereum, getBlockNumber } = useWallet()
    const img = "https://cloudflare-ipfs.com/ipfs/QmTWzBGYm8HrmiVRwcxarQ3yzUaXD3vHw5XcypZxYUq86S"
    if (account) {
        return <>
            <IonText color="success">
                {formatEther(balance).slice(0, 12)} FTM
            </IonText>
            <IonChip color="success">
                <IonImg src={img} />
                {account.slice(0, 6)}...{account.slice(38, 55)}
            </IonChip>
            {error}
        </>
    }
    return (<IonButton fill='solid' color="primary" onClick={() => {
        connect("injected")
    }}> Connect
    </IonButton >);
};

export default LoginButton;
