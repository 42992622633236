import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonLoading, IonPage, IonProgressBar, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { BigNumber, ContractTransaction, ethers } from 'ethers';
import { arrowBack, documentLockOutline, sparklesOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';
import LoginButton from '../components/LoginButton';
import { Arcturians } from '../contracts/Arcturians';
import { Arcturians__factory } from '../contracts/factories/Arcturians__factory';
import { MagicalItem__factory } from '../contracts/factories/MagicalItem__factory';
import { MagicalItem } from '../contracts/MagicalItem';
import { contractAddress } from './Mint';
import { config } from '../config';
import { PotionPalace__factory } from '../contracts/factories/PotionPalace__factory';
import { PotionPalace } from '../contracts/PotionPalace';


export const reloadPage = () => {
  const url = new URL(window.location.href);
  url.searchParams.set('reloadTime', Date.now().toString());
  window.location.href = url.toString();
}

export interface NFTBalance {
  token_address: string;
  token_id: string;
  contract_type: string;
  owner_of: string;
  block_number: string;
  block_number_minted: string;
  token_uri?: string | undefined;
  metadata?: string | undefined;
  synced_at?: string | undefined;
  amount?: string | undefined;
  name: string;
  symbol: string;
}
interface NFTMetadata {
  token_address: string;
  name: string;
  abi?: string | undefined;
  supports_token_uri?: number | undefined;
  synced_at?: string | undefined;
  symbol: string;
  contract_type: string
}

export const NFTCard: React.FC<{ nft: NFTBalance }> = ({ nft }) => {
  const [nftMeta, setNFTMetadata] = useState<any | undefined>(nft.metadata)
  useEffect(() => {
    (async () => {

    })()

  }, [nft])
  return <IonCard title={nft.name} color="light">
    <IonCardHeader color="paper">
      <IonTitle size='large'>
        <h2>
          {nft.name || nft.symbol || (nftMeta && nftMeta.description)}
        </h2>
      </IonTitle>

    </IonCardHeader>
    <IonCardContent>
      {nftMeta && nftMeta.image && <IonImg style={{ width: 612, height: 612 }} src={nftMeta.image.replace("ipfs://", "https://smol.mypinata.cloud/ipfs/")} />}
      <IonText color="medium">
        <IonItemDivider >
          {nftMeta && nftMeta.description || ""}
        </IonItemDivider>
      </IonText>
      <IonItemDivider color="clear" />
      {Object.values(nftMeta.attributes).map((x: any, i) => <IonChip key={i} color="medium">
        {x && x.trait_type && < IonLabel color="tertiary">
          {String(x.trait_type)}:
        </IonLabel>}
        {x && x.value && String(x.value)}
      </IonChip>)}
    </IonCardContent>
  </IonCard >
}


export const ArcturianCard: React.FC<{ metadata: any, id: number }> = ({ metadata, id }) => {
  const nft = metadata;
  const nftMeta = metadata;
  const isAnimated = typeof nftMeta.animation_url !== "undefined"
  return <IonCard title={nft.name}>
    <IonItem color="tertiary">
      <IonTitle>
        {nft.name || nft.symbol || (nftMeta && nftMeta.description)}
      </IonTitle>
    </IonItem>
    <IonCardContent>
      {nftMeta && nftMeta.image && <IonImg src={String(nftMeta.animation_url || nftMeta.image).replace("ipfs://", "https://smol.mypinata.cloud/ipfs/")} />}
      <IonText color="medium">
        {nftMeta && nftMeta.description || ""}
      </IonText>
      <IonItemDivider color="clear" />
      {Object.values(nftMeta.attributes).map((x: any, i) => <IonChip key={i} color="medium">
        {x && x.trait_type && < IonLabel color="tertiary">
          {String(x.trait_type)}:
        </IonLabel>}
        {x && x.value && String(x.value)}
      </IonChip>)}
    </IonCardContent>
  </IonCard >
}



export const AsyncERC1155Card: React.FC<{ address: string, id: string, name: string, balance: string }> = ({ name, id, address, balance }) => {

  const nft = useState();
  const [nftMeta, setNftMeta] = useState<Record<string, string> | undefined>(undefined);
  const { ethereum } = useWallet()
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();

  useEffect(() => {


    const nftContract: MagicalItem | undefined = signer && MagicalItem__factory.connect(address, signer);
    nftContract && id && nftContract.uri(id).then((address) => {
      id && fetch(address.replace("{id}", id)).then(async (res) => {
        const json = await res.json();
        setNftMeta(json);
      })
    });



  }, [])
  return <IonCard title={name}>
    <IonItem color="primary">
      <IonTitle>
        {balance}x {nftMeta && nftMeta.name}
      </IonTitle>
    </IonItem>
    <IonCardContent>
      {nftMeta && nftMeta.image && <IonImg src={nftMeta.image.replace("ipfs://", "https://smol.mypinata.cloud/ipfs/")} />}

      <IonText color="medium">
        {nftMeta && nftMeta.description || ""}
      </IonText>
      <IonItemDivider color="clear" />
      {nftMeta && Object.values(nftMeta.attributes).map((x: any, i) => <IonChip key={i} color="medium">
        {x && x.trait_type && < IonLabel color="tertiary">
          {String(x.trait_type)}:
        </IonLabel>}
        {x && x.value && String(x.value)}
      </IonChip>)}
    </IonCardContent>
  </IonCard >
}


export const AsyncNFTCard: React.FC<{ title?: string, actions?: React.FC<{ tokenId: number, tokenAddress: string }>, showInfo?: boolean, address: string, id: string, name: string, }> = ({ actions, name, id, address, showInfo = true, title }) => {

  const nft = useState();
  const [nftMeta, setNftMeta] = useState<Record<string, string> | undefined>(undefined);
  const { connect, ethereum, account } = useWallet()
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  useEffect(() => {


    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(address, signer);
    id && fetch("/assets/metadata/" + id + ".json").then(async (res) => {
      const json = await res.json();
      setNftMeta(json);
    })

  }, [id])
  return <IonCard title={name}>
    {<IonItem color="tertiary">
      <IonTitle>
        {title ? title : nftMeta ? nftMeta.name : ""}
      </IonTitle>
    </IonItem>}
    <IonCardContent>
      {nftMeta && nftMeta.image && <IonImg src={"/assets/images/" + id + ".png"} />}

      <IonText color="medium">
        {showInfo && nftMeta && nftMeta.description || ""}
      </IonText>
      <IonItemDivider color="clear" />
      {nftMeta && Object.values(nftMeta.attributes).map((x: any, i) => <IonChip key={i} color="medium">
        {x && x.trait_type && < IonLabel color="tertiary">
          {String(x.trait_type)}:
        </IonLabel>}
        {x && x.value && String(x.value)}
      </IonChip>)}
      {actions && actions({ tokenId: parseInt(id), tokenAddress: config.nftContractAddress })}
    </IonCardContent>
  </IonCard >
}

export const ActionButton: React.FC<{ tokenId: number, tokenAddress: string }> = ({ tokenAddress, tokenId }) => {
  const { ethereum, account } = useWallet();
  const [stakeTransaction, setStakeTransaction] = useState<ContractTransaction | undefined>()
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  const unstake = useCallback(() => {
    const nftContract: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
    account && nftContract && tokenId && nftContract.enterPalace([BigNumber.from(tokenId)]).then((res) => {
      setStakeTransaction(res);
    }).catch((e) => {
    })
  }, [account, signer, ethereum])
  return <>
    <IonButton onClick={unstake} expand='full' color="clear">
      Stake
    </IonButton>
    {stakeTransaction && <a target="_blank" href={config.explorerUrl + "tx/" + stakeTransaction?.hash}>
      <IonChip color="tertiary"> View transaction {stakeTransaction?.hash.slice(0, 7)} on block explorer</IonChip>
    </a>
    }

  </>
}
export const UnstakeButton: React.FC<{ tokenId: number, tokenAddress: string }> = ({ tokenAddress, tokenId }) => {
  const { ethereum, account } = useWallet();
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  const [stakeTransaction, setStakeTransaction] = useState<ContractTransaction | undefined>()

  const unstake = useCallback(() => {
    const nftContract: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
    account && nftContract && tokenId && nftContract.exitPalace(BigNumber.from(tokenId)).then((res) => {
      setTransacting(true);
    }).catch((e) => {
    })
  }, [account, signer, ethereum])
  const [transacting, setTransacting] = useState(false);
  return <>
    <IonButton onClick={unstake} expand='full' color="clear">
      unstake
    </IonButton>
    {stakeTransaction && <a target="_blank" href={config.explorerUrl + "tx/" + stakeTransaction?.hash}>
      <IonChip color="tertiary"> View transaction {stakeTransaction?.hash.slice(0, 7)} on block explorer</IonChip>
    </a>
    }
  </>
}

function delay(delayInms: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export const CollectionInventory: React.FC<{ actions?: React.FC<{ tokenId: number, tokenAddress: string }>, size?: "small" | "large", contract_address: string, name: string }> = ({ contract_address, name, size = "large", actions }) => {
  const [supply, setSupply] = useState<number | undefined>()
  const { ethereum, account } = useWallet();
  const [magePunkOwners, setArcturianOwners] = useState<Record<string, string>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contract_address, signer);
    nftContract && nftContract.totalSupply().then((amount) => {
      setSupply(amount.toNumber())
    }).catch(() => {

    });


  }, [account]);
  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contract_address, signer);
    supply && Array.from(Array(supply)).forEach(async (x, i) => {
      const id = i + 1;
      await delay(50);
      account && signer && nftContract?.ownerOf(i + 1).then((res) => {
        if (res === account) {
          setMyPunks(x => {
            if (x.includes(i + 1)) {
              return x;
            }
            return ([...x, i + 1])
          })
        }
        setArcturianOwners(x => ({ [id]: res }));
      }).catch(() => {

      })
    });
  }, [account, supply])
  return (
    <>
      {myPunks.map((id) => <IonCol key={id} size={size === "large" ? "3" : "2"}> <AsyncNFTCard title={String(id)} address={contractAddress} actions={actions || undefined} showInfo={true} id={id.toString()} name={name} /></IonCol>)}
    </>
  );
};



export const StakedCollectionInventory: React.FC<{ currentSupply: number, actions?: React.FC<{ tokenId: number, tokenAddress: string }>, size?: "small" | "large", contract_address: string, name: string }> = ({ contract_address, name, size = "large", actions, currentSupply }) => {
  const [supply, setSupply] = useState<number | undefined>(currentSupply)
  const { ethereum, account, getBlockNumber } = useWallet();
  const [magePunkBrewingStarted, setBrewStartBlock] = useState<Record<string, number>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const [approved, setApproved] = useState<boolean>(false);
  const [totalPotionsBrewed, setTotalPotions] = useState<number>(0);
  const [potionPerBlock, setPotionPerBlock] = useState<BigNumber | undefined>(BigNumber.from(170000000000));
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  const [status, setStatus] = useState<"idle" | "transacting">();
  const calcPotionEarned = useCallback((id: number) => {
    if (typeof potionPerBlock === "undefined" || typeof getBlockNumber === 'undefined' || typeof magePunkBrewingStarted === 'undefined') {
      return "?";
    }
    const blockNumber = getBlockNumber() || 0
    if (blockNumber === 0 || magePunkBrewingStarted[id] === 0 || typeof magePunkBrewingStarted[id] === 'undefined') {
      return "?";
    }
    return ethers.utils.formatEther(potionPerBlock.mul((blockNumber - magePunkBrewingStarted[id])));

  }, [potionPerBlock, magePunkBrewingStarted, getBlockNumber])


  const calcAllPotionEarned = useCallback((ids: number[]) => {
    return ids.map(x => calcPotionEarned(x)).map(parseFloat).reduce((a, b) => {
      return a + b;
    }, 0);

  }, [potionPerBlock, magePunkBrewingStarted, getBlockNumber])




  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(config.nftContractAddress, signer);
    account && nftContract?.isApprovedForAll(account, config.stakingContractAddress).then((res) => {
      setApproved(res);
    }).catch(() => {
      console.log(
        "Approval request error"
      )
    })
  }, [account]);


  useEffect(() => {
    const nftContract: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.nftContractAddress, signer);
    account && nftContract!.potionPerBlock().then((res) => {
      setPotionPerBlock(res);
    }).catch((e) => {
      console.log("WTF", e);
    })
  }, [account]);
  useEffect(() => {
    const nftContract: PotionPalace | undefined = signer && PotionPalace__factory.connect(contract_address, signer);
    supply && Array.from(Array(supply)).forEach(async (x, i) => {
      const id = i + 1;
      account && signer && nftContract?.receipt(id).then(([magePunkId, startedBrewing, owner]) => {
        if (owner === account) {
          setMyPunks(x => {
            if (x.includes(id)) {
              return x;
            }
            return ([...x, id])
          })
        }
        startedBrewing.toNumber() > 0 && setBrewStartBlock(x => ({ [id]: startedBrewing.toNumber(), ...x }));
      }).catch(() => {
        console.log("Error gettings brewed gwei of potion or staking receipt")
      })
    });
  }, [account, supply])
  return (
    <>
      {myPunks.map((id) => <IonCol key={id} size={size === "large" ? "2" : "2"}> <AsyncNFTCard address={contractAddress} actions={actions || undefined} showInfo={size === "large"} id={id.toString()} name={name} /></IonCol>)}
      <IonCol size='12'>


        <IonProgressBar value={calcAllPotionEarned(myPunks)} />
        {myPunks && <IonButton size='large' expand='full' color="tertiary" onClick={() => {
          const potionPalace: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
          potionPalace && potionPalace.collectAll(myPunks).then(() => {
            setStatus("idle");
          });
        }}>
          Collect  {calcAllPotionEarned(myPunks)} Potion ⚗️
        </IonButton>}
      </IonCol>
      <IonItem href={config.explorerUrl + "/address/" + config.stakingContractAddress} >
        <IonIcon icon={sparklesOutline} />
        <IonText color="medium">
          Potion Palace Contract {config.stakingContractAddress}
        </IonText>
      </IonItem>
    </>
  );
};



export const UnStakedCollectionInventory: React.FC<{ currentSupply: number, actions?: React.FC<{ tokenId: number, tokenAddress: string }>, size?: "small" | "large", contract_address: string, name: string }> = ({ contract_address, name, size = "large", actions, currentSupply }) => {
  const [supply, setSupply] = useState<number | undefined>(0)
  const { ethereum, account } = useWallet();
  const [magePunkOwners, setArcturianOwners] = useState<Record<string, string>>();
  const [myPunks, setMyPunks] = useState<number[]>([]);
  const [approved, setApproved] = useState<boolean>(false);
  const [transacting, setTransacting] = useState<boolean>(false);
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(config.nftContractAddress, signer);
    account && nftContract?.isApprovedForAll(account, config.stakingContractAddress).then((res) => {
      setApproved(res);
      console.log("approved", res);
    })
    account && nftContract?.totalSupply().then((res) => {
      setSupply(res.toNumber());
      console.log("supply", res.toString());
    })
  }, [account]);
  useEffect(() => {
    const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contract_address, signer);
    Array.from(Array(108)).forEach(async (x, i) => {
      const id = i + 1;
      await delay(70);

      account && signer && nftContract?.ownerOf(i + 1).then((owner) => {
        if (owner === account) {
          setMyPunks(x => {
            if (x.includes(i + 1)) {
              return x;
            }
            return ([...x, i + 1])
          })
        }
        setArcturianOwners(x => ({ [id]: account, ...x }));
      }).catch(() => { })
    });
  }, [account])
  return (
    <>
      {myPunks.map((id) => <IonCol key={id} size={size === "large" ? "3" : "2"}> <AsyncNFTCard address={contractAddress} actions={actions || undefined} showInfo={size === "large"} id={id.toString()} name={name} /></IonCol>)}
      <IonCol size='12'>

        {!approved && <IonButton onClick={() => {
          const nftContract: Arcturians | undefined = signer && Arcturians__factory.connect(contractAddress, signer);
          nftContract && nftContract.setApprovalForAll(config.stakingContractAddress, true).then(() => {
          });
        }}>
          Approve Palace
        </IonButton>}


        {myPunks && myPunks.length > 0 && < IonButton color="tertiary" onClick={() => {
          const potionPalace: PotionPalace | undefined = signer && PotionPalace__factory.connect(config.stakingContractAddress, signer);
          potionPalace && potionPalace.enterPalace(myPunks).then(() => {
            setTransacting(true)
          });
        }}>
          Stake all
        </IonButton>}
        {transacting && <IonLoading isOpen={true} />}
      </IonCol>
    </>
  );
};


export const ERC1155CollectionInventory: React.FC<{ contract_address: string, name: string }> = ({ contract_address, name }) => {
  const { ethereum, account, chainId, isConnected } = useWallet();
  const [inventory, setInventory] = useState<Record<string, string>>();
  const signer = ethereum && (new ethers.providers.Web3Provider(ethereum)).getSigner();
  useEffect(() => {
    const nftContract: MagicalItem | undefined = signer && MagicalItem__factory.connect(contract_address, signer);
    Array.from(Array(12)).forEach(async (x, i) => {
      const id = i + 1;
      await new Promise(resolve => setTimeout(resolve, 100));
      try {
        account && signer && nftContract?.balanceOf(account, i + 1).then((balance) => {
          setInventory(x => ({
            ...x, [id]: balance.toNumber()
          }))
        }).catch(() => { })
      } catch {

      }

    });
  }, [account, chainId, isConnected])
  return (
    <>
      {inventory && Object.entries(inventory).filter(([id, balance]) => balance && balance !== "0").map(([id, balance]) =>
        <IonCol key={id} size="2">
          <AsyncERC1155Card key={id} balance={balance} name='Magical Item' address={contract_address} id={id} />
        </IonCol>)
      }
    </>
  );
};

const Inventory: React.FC = () => {
  const { isConnected } = useWallet();

  return (
    <IonPage>
      <IonHeader>
        <IonItem>
          <IonButtons slot="start">

            <IonButton routerLink='/' routerDirection='root' color="dark" fill='clear'>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="tertiary">Collection</IonTitle>
          <IonButtons slot="end">
            <LoginButton />
          </IonButtons>
        </IonItem>
      </IonHeader >
      <IonContent>
        <IonGrid>
          {isConnected() ? <IonRow>
            <CollectionInventory name='Arcturian' contract_address={contractAddress} />
            {/* <ERC1155CollectionInventory name='Magical Item' contract_address={"0xa6e6e61e2039Ef69AeCd449040470368d28575f1"} /> */}
          </IonRow> : <LoginButton />}
        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Inventory;
